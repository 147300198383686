import React from "react";

import {
    AccountInfo,
    DefaultLayout,
    Layout,
    withPrivateRoute,
} from "@containers";

const AccountInfoPage = props => (
    <Layout {...props}>
        <DefaultLayout title="Account info">
            <AccountInfo />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(AccountInfoPage);
